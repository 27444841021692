import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from './images/logo.png'; 

const Header = () => {
const [menuOpen, setMenuOpen] = useState(false);
const [activeLink, setActiveLink] = useState("");
const [projectsOpen, setProjectsOpen] = useState(false); 

 
const toggleMenu = () => {
setMenuOpen(!menuOpen);
};

// Handle click on menu links
const handleLinkClick = (linkName) => {
setActiveLink(linkName);
setMenuOpen(false); 
setProjectsOpen(false); 
};

// Toggle Projects dropdown visibility
const handleProjectsClick = () => {
setProjectsOpen(!projectsOpen);
};

return (
<header className="header">
{/* Logo Section */}
<div className="logo">
<img src={logo} alt="Logo" />
</div>

{/* Hamburger Menu */}
<div className={`hamburger ${menuOpen ? "active" : ""}`} onClick={toggleMenu}>
<span></span>
<span></span>
<span></span>
</div>

{/* Navigation Menu */}
<ul className={`menu ${menuOpen ? "open" : ""}`}>
<li>
<Link
to="/"
className={activeLink === "Home" ? "active" : ""}
onClick={() => handleLinkClick("Home")}
 >
Home
</Link>
</li>

{/* Projects Dropdown */}
<li className={`projects ${projectsOpen ? "open" : ""}`}>
<span
className={activeLink === "Projects" ? "active" : ""}
onClick={handleProjectsClick}
>
Projects{" "}
<span className={`arrow ${projectsOpen ? "down" : "up"}`}>^</span>
</span>
{projectsOpen && (
<ul className="dropdown">
<li>
<Link
to="/projects"
className={activeLink === "Projects Page" ? "active" : ""}
onClick={() => handleLinkClick("Projects Page")}
>
Projects
</Link>
</li>
<li>
 <Link
 to="/buildcostpro"
 className={activeLink === "/buildcostpro" ? "active" : ""}
 onClick={() => handleLinkClick("/buildcostpro")}
>
 BuildCostPro
</Link>
 </li>
 <li>
 <Link
 to="/materials"
 className={activeLink === "Materials" ? "active" : ""}
 onClick={() => handleLinkClick("Materials")}
 >
 Materials
 </Link>
</li>
 </ul>
 )}
</li>
{/* Other Links */}
<li>
<Link
to="/jobs"
className={activeLink === "Jobs" ? "active" : ""}
onClick={() => handleLinkClick("Jobs")}
>
Jobs
</Link>
</li>
<li>
<Link
to="/contact"
className={activeLink === "Contact" ? "active" : ""}
onClick={() => handleLinkClick("Contact")}
>
Contact
</Link>
</li>

<li>
<Link
to="/reviews"
className={activeLink === " Reviews" ? "active" : ""}
onClick={() => handleLinkClick(" Reviews")}
 >
Reviews
</Link>
</li>
<li>
<Link
to="/about"
className={activeLink === "About" ? "active" : ""}
onClick={() => handleLinkClick("About")}
>
 About
</Link>
</li>
</ul>
</header>
);
};

export default Header;