import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import BuildCostImage from './images/buildcostimage.png'; 
const BuildCostPro = () => {
  const [objectType, setObjectType] = useState("");
  const [area, setArea] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedQuality, setSelectedQuality] = useState("first");
  const [WORKER, setWORKER] = useState(0);
  const [TRANSPORT, setTRANSPORT] = useState(0);
  const [DISCOUNT, setDISCOUNT] = useState(0);
  const [TAX, setTAX] = useState(0);
  const [totalCost, setTotalCost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const navigate = useNavigate();

  const objectTypes = [
    { id: 1, type: "Residential", price: 100, qualityPrices: { first: 100, second: 90, third: 80 }, workerCost: 20, transportCost: 10, discount: 5, tax: 18 },
    { id: 2, type: "House", price: 120, qualityPrices: { first: 120, second: 110, third: 100 }, workerCost: 25, transportCost: 12, discount: 7, tax: 20 },
    { id: 3, type: "Commercial", price: 150, qualityPrices: { first: 150, second: 140, third: 130 }, workerCost: 30, transportCost: 15, discount: 10, tax: 22 },
    { id: 4, type: "Industrial", price: 200, qualityPrices: { first: 200, second: 180, third: 170 }, workerCost: 35, transportCost: 20, discount: 12, tax: 25 }
  ];
  const isLoggedIn = () => {
    const user = localStorage.getItem('user');
    return user !== null; 
  };
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/buildcostpro"); 
    } else {
      localStorage.removeItem("totalCost");
      localStorage.removeItem("objectType");
      localStorage.removeItem("area");
      localStorage.removeItem("selectedPrice");
    }
  }, [navigate]);

  const handleObjectTypeChange = (e) => {
    const selected = objectTypes.find(obj => obj.id === parseInt(e.target.value));
    if (selected) {
      setObjectType(selected.type);
      setSelectedPrice(selected.qualityPrices[selectedQuality]); 
      setWORKER(selected.workerCost);
      setTRANSPORT(selected.transportCost);
      setDISCOUNT(selected.discount);
      setTAX(selected.tax);
    }
  };

  const handleQualityChange = (e) => {
    setSelectedQuality(e.target.value);
    const updatedPrice = objectTypes.find(obj => obj.type === objectType).qualityPrices[e.target.value];
    setSelectedPrice(updatedPrice); 
  };

const calculateCost = () => {
if (!objectType || area <= 0 || !selectedQuality) {
alert("Please select a valid object type, area, and quality.");
 return;
}

let cost = area * selectedPrice + area * WORKER + area * TRANSPORT;
 cost += (cost * TAX) / 100;
cost -= (cost * DISCOUNT) / 100;

setTotalCost(cost);
setIsModalOpen(true);
};
  
const handlePurchase = () => {
if (!isLoggedIn()) {
alert("You need to be logged in to make a purchase.");
navigate("/reviews");  
return;
 }
  
 navigate("/payments", { state: { totalCost, objectType, area } }); 
 return;
  };

  const closeModal = () => {
  setIsModalOpen(false); 
  };

  return (
<div>
<Header />
<div className="container-section">
<section className="intro-section">
<div className="card">
 <h3>Welcome to the Construction Cost Calculator</h3>
  <ul>
 <li><strong>Step 1:</strong> Choose the object type.</li>
 <li><strong>Step 2:</strong> Enter the area.</li>
 <li><strong>Step 3:</strong> Select the quality and review auto-filled costs.</li>
<li><strong>Step 4:</strong> Calculate the total cost.</li>
</ul>
 </div>
 <img src={BuildCostImage} alt="BuildCostImage" className="buildcostimage" />
  </section>
  
 <div className="container">
 <h2 className="title">BuildCostPro</h2>

 {/* ObjectType */}
 <div className="formGroup">
 <label htmlFor="objectType" className="label">Object Type:</label>
  <select
 id="objectType"
  onChange={handleObjectTypeChange}
 className="select"
 >
 <option value="">Select Object Type</option>
 {objectTypes.map(obj => (
  <option key={obj.id} value={obj.id}>
  {obj.type} (€{obj.price}/m²)
</option>
 ))}
</select>
</div>

 {/* Area */}
<div className="formGroup">
<label htmlFor="area" className="label">Area (m²):</label>
<input
type="number"
id="area"
value={area}
onChange={(e) => setArea(Number(e.target.value))}
 placeholder="Enter total area"
 className="input"
/>
</div>

{/* Quality */}
<div className="formGroup">
<label htmlFor="quality" className="label">Quality:</label>
 <select
id="quality"
value={selectedQuality}
onChange={handleQualityChange}
className="select"
 >
<option value="first">First Quality</option>
<option value="second">Second Quality</option>
<option value="third">Third Quality</option>
</select>
</div>

{/* Price */}
 <div className="formGroup">
 <label>Price per Square Meter (€):</label>
  <input type="number" value={selectedPrice} readOnly className="input" />
  </div>

 {/* Cost*/}
<div className="formGroup">
<label>Worker Cost (€ / m²):</label>
 <input type="number" value={WORKER} readOnly className="input" />
 </div>

<div className="formGroup">
<label>Transport Cost (€ / m²):</label>
 <input type="number" value={TRANSPORT} readOnly className="input" />
</div>

 <div className="formGroup">
 <label>Discount (%):</label>
 <input type="number" value={DISCOUNT} readOnly className="input" />
  </div>

 <div className="formGroup">
 <label>Tax Percentage (%):</label>
 <input type="number" value={TAX} readOnly className="input" />
 </div>

   <button onClick={calculateCost} className="button">Calculate Total Cost</button>

  {totalCost !== null && (
  <div className="result">
 <h3>Total Estimated Cost: €{totalCost.toFixed(2)}</h3>
 <button onClick={handlePurchase} className="button">Buy Project</button>
 </div>
 )}
 </div>
 </div>

 {isModalOpen && (
 <div className="modal">
 <div className="modal-content">
 <h2>Are you interested in purchasing this property and starting the construction process?</h2>
 <button onClick={handlePurchase} className="button">Yes</button>
<button onClick={closeModal} className="button">No</button>
</div>
  </div>
  )}

<Footer />
</div>
);
};

export default BuildCostPro;








