import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './HomePage';
import Projects from './Projects';
import Jobs from './Jobs'; 
import Contact from './Contact';
import About from './About';
import Application from './Application';
import ApplicationsList from './ApplicationsList';
import  Reviews from './Reviews';
import Header from "./Header"; 
import Footer from "./Footer"; 
import CostCalculator from "./BuildCostPro";
import Materials from "./Materials";
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import Payments from "./Payments";
import Confirmation from "./Confirmation";
function App() {
  return (
    <Router>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/buildcostpro" element={<CostCalculator />} />
        <Route path="/materials" element={<Materials />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/application" element={<Application/>} />
        <Route path="/applications" element={<ApplicationsList />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/reviews" element={<Reviews />} /> 
        <Route path="/header" element={<Header />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/confirmation" element={<Confirmation />} />
      </Routes>
    </Router>
  );
}

export default App;