import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import './index.css';

const jobListings = [
    { id: 1, title: 'Construction Engineer', location: 'Tirana, Albania', description: 'We are looking for a construction engineer to lead new projects and ensure the quality of work.', link: '/jobs/construction-engineer' },
    { id: 2, title: 'Construction Worker', location: 'Tirana, Albania', description: 'We are seeking qualified workers to work on various construction projects.', link: '/jobs/construction-worker' },
    { id: 3, title: 'Construction Foreman', location: 'Tirana, Albania', description: 'We need a construction foreman to lead the construction team and manage materials.', link: '/jobs/construction-foreman' },
    { id: 4, title: 'Construction Archaeologist', location: 'Tirana, Albania', description: 'We are looking for an archaeologist to monitor construction projects and ensure the preservation of cultural heritage.', link: '/jobs/construction-archaeologist' },
    { id: 5, title: 'Architectural Designer', location: 'Tirana, Albania', description: 'We need an architectural designer to draft plans and sketches for construction projects.', link: '/jobs/architectural-designer' },
    { id: 6, title: 'Project Coordinator', location: 'Tirana, Albania', description: 'We are looking for a project coordinator to manage and organize resources for construction projects.', link: '/jobs/project-coordinator' },
    { id: 7, title: 'Structural Engineer', location: 'Tirana, Albania', description: 'We are looking for a structural engineer to develop plans and calculations for various construction structures.', link: '/jobs/structural-engineer' },
    { id: 8, title: 'Installation Foreman', location: 'Tirana, Albania', description: 'We are seeking an installation foreman to manage and carry out installation work on construction projects.', link: '/jobs/installation-foreman' },
];

function Jobs() {
const [searchTerm, setSearchTerm] = useState('');
 const [applications, setApplications] = useState([]);
const [showMessage, setShowMessage] = useState(false); 
const navigate = useNavigate();

    // Filter job listings
const filteredJobs = jobListings.filter(job => 
job.title.toLowerCase().includes(searchTerm.toLowerCase())
);

// Show message 
useEffect(() => {
if (filteredJobs.length === 0) {
setShowMessage(true);
} else {
setShowMessage(false);
}
}, [searchTerm, filteredJobs]);

useEffect(() => {
const user = JSON.parse(localStorage.getItem('user'));
if (user) {
// Fetch applications 
 const fetchApplications = async () => {
const response = await fetch(`https://hocompany.website/api/applications.php?email=${user.email}`);
const data = await response.json();
setApplications(data);
};

fetchApplications();
 }
}, []);

const handleApplyNow = (jobTitle) => {
    navigate('/application', { state: { jobTitle } });
    };

return (
<div>
<Header />

{/* Search Container */}
<div className="search-container">
<input
type="text"
 placeholder="Search for construction jobs..."
 value={searchTerm}
onChange={(e) => setSearchTerm(e.target.value)}
 className="search-input"
 />
</div>

 {/* Job Listings */}
 <h2>Available Construction Jobs</h2>
<div className="job-listings">
 {filteredJobs.map(job => (
<div className="job-card" key={job.id}>
<h3>{job.title}</h3>
 <p>{job.location}</p>
<p>{job.description}</p>
 <div className="button-container">
 <button 
 onClick={() => handleApplyNow(job.title)} 
className="apply-button"
 >
Apply Now
 </button>
</div>
 </div>
 ))}
 </div>

{/* Show message if no jobs found */}
 {showMessage && (
 <div className="no-jobs-message">
 <p>No jobs found for your search term.</p>
  </div>
)}

{/* User Applications */}
<div className="applications-section">
 <h2>Your Applications:</h2>
{applications.length > 0 ? (
applications.map((app, index) => {
// Get the application date and calculate the difference in days
const applicationDate = new Date(app.created_at);
const currentDate = new Date();
const differenceInTime = currentDate - applicationDate;
const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
// Determine the status based on the difference in days
 const status = differenceInDays <= 30 ? 'Pending' : 'Closed';
const statusClass = status === 'Pending' ? 'pending' : 'closed';

return (
<div key={index} className="application-card">
<h3><strong>{app.name || 'N/A'}</strong></h3>
<p><strong>Job Title:</strong> {app.jobTitle || 'N/A'}</p>
 <p><strong>Applied on:</strong> {app.created_at ? new Date(app.created_at).toLocaleDateString() : 'N/A'}</p>
<p className={`status ${statusClass}`}><strong>Status:</strong> {status}</p>
</div>
);
})
) : (
<p>You have not applied to any jobs yet.</p>
    )}
</div>

<Footer />
</div>
);
}

export default Jobs;