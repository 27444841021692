import React, { useState,useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './index.css';
import Footer from "./Footer"; 
import Header from "./Header"; 

function Application() {
  const location = useLocation();
  const jobTitle = location.state?.jobTitle || "Job Title";
  console.log('Location State:', location.state);
  console.log('Job Title:', jobTitle);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  
useEffect(() => {
  const savedUser = JSON.parse(localStorage.getItem('user')); 
  if (savedUser && savedUser.email === 'erahidaj@gmail.com') {
    setIsAdmin(true); 
  }
}, []);
const validateEmail = (email) => {
const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
return re.test(String(email).toLowerCase());
};

const handleSubmit = (e) => {
e.preventDefault();
if (!validateEmail(email)) {
setError('Please enter a valid email address.');
return;
}
 setError('');

 const formData = new FormData();
        
formData.append('name', name);
formData.append('email', email);
formData.append('resume', resume);
formData.append('jobTitle', jobTitle);

fetch('https://hocompany.website/api/save-application.php', {
method: 'POST',
 body: formData,
})
.then(response => response.json())
.then(data => {
console.log(data); 
setMessage(data.message);
setModalVisible(true);
setName('');
setEmail('');
setResume(null);
})
.catch(error => console.error('Error:', error));
};
return (
<div>
{/* Header Section */}
<Header/>
 <div className="application-form">
<h2>Applying for: <strong>{jobTitle}</strong></h2>
<form onSubmit={handleSubmit}>
<div className="form-group">
 <label htmlFor="name">Name:</label>
<input
 type="text"
 id="name"
 value={name}
onChange={(e) => setName(e.target.value)}
 required
/>
</div>
<div className="form-group">
<label htmlFor="email">Email:</label>
<input
type="email"
id="email"
value={email}
onChange={(e) => setEmail(e.target.value)}
required
/>
{error && <div className="error">{error}</div>}
</div>
<div className="form-group">
<label htmlFor="resume">Resume:</label>
<input
type="file"
id="resume"
accept=".pdf"
onChange={(e) => setResume(e.target.files[0])}
required
/>
</div>
<button type="submit">Submit Application</button>
</form>
</div>

{modalVisible && (
 <div className="modal">
<div className="modal-content">
<span className="close" onClick={() => setModalVisible(false)}>&times;</span>
<h2>Form Submitted!</h2>
 <i className="fas fa-check-circle" style={{ color: 'green', fontSize: '35px' }}></i>
 <p>{message}</p>
</div>
</div>
 )}
{isAdmin && (
  <Link to="/applications">See Applications</Link>
)}
<Footer/>
</div>
    );
}

export default Application;
